import { ref } from 'vue'

export default function () {
  let inputs = ref({
    firstName: '',
    lastName: '',
    mobileNumber: '',
    company: {
      value: "",
      id: "",
    },
    industry: {
      value: "",
      id: "",
    },
    work: {
      company: '',
      industry: '',
      position: ''
    }
  })

  let inputErrors = ref({
    firstName: false,
    lastName: false,
    mobileNumber: false,
    work: {
      company: false,
      position: false
    }
  })

  let populateInputs = profile => {
    inputs.value.firstName = profile.firstName
    inputs.value.lastName = profile.lastName
    inputs.value.mobileNumber = profile.mobileNumber
    inputs.value.work.company = profile.work.company
    inputs.value.work.industry = profile.work.industry
    inputs.value.work.position = profile.work.position
    inputs.value.company = {
      ...profile.company
    };
    inputs.value.industry = {
      ...profile.industry
    };
  }

  let validateInput = field => {
    if (field === "company") {
      inputErrors.value.company = !inputs.value.company.value;

      return !inputErrors.value.company;
    }

    if (field === "industry") {
      if (
        (inputs.value.industry.id === "*" && !inputs.value.industry.value) ||
        !inputs.value.industry.id
      ) {
        inputErrors.value.industry = true;
      } else {
        inputErrors.value.industry = false;
      }
      return !inputErrors.value.industry;
    }


    if (field === 'position') {
      inputErrors.value.work.position = !inputs.value.work.position
      return !inputErrors.value.work.position
    }
  }

  let validateInputs = () => {
    if (![
      validateInput('company'),
      validateInput("industry"),
      validateInput('position')
    ].every(Boolean)) return null

    let {
      firstName,
      lastName,
      mobileNumber,
      industry,
      company,
      work
    } = inputs.value

    return {
      firstName,
      lastName,
      mobileNumber,
      industry: {
        ...(industry.id === "*" ? {} : { id: industry.id }),
        ...(industry.value ? { value: industry.value } : {}),
      },
      company,
      work
    }
  }

  return {
    inputs,
    inputErrors,
    populateInputs,
    validateInput,
    validateInputs
  }
}
