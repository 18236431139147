<template>
  <template v-if="step === 'consent' && profile">
    <div class="qfd">
      <div class="hnq">Corporate Innovation Maturity Assessment</div>
      <div class="vzc">15 minutes to complete</div>
      <div class="ess">
        First of all, by participating in this assessment, you consent to the
        transfer of the information you submit to RISE.
      </div>
      <div class="ess">
        The information collected is for internal research purposes only and
        will not be publicly assiciated with your account.
      </div>
      <checkbox-element class="accept-consent" v-model="consent">
        I Accept *
      </checkbox-element>
      <div v-if="!consent" class="accept-consent-remark">
        * This question is required
      </div>
    </div>
    <router-link class="npe" :to="{ name: 'start' }">
      <div class="abo">Back</div>
    </router-link>
    <div class="jcc">
      <button-element @click="step = 'updateProfile'" :disabled="!consent">
        START
      </button-element>
    </div>
  </template>

  <template v-else-if="step === 'updateProfile'">
    <div class="qfd">
      <div class="hnq">Corporate Innovation Maturity Assessment</div>
      <div class="jsa">Please fill in your company information.</div>

      <input-element
        type="text"
        label="Position"
        v-model="inputs.work.position"
        :error="inputErrors.work.position ? 'Field is required' : ''"
        @blur="validateInput('position')"
      />
      <base-input-with-suggestion
        class="margin-top"
        v-model="inputs.company"
        :options="companiesOptions"
        label="Company"
        :error="inputErrors.company ? 'Field is required' : ''"
        @update:model-value="updateCompanyValue"
        @blur="validateInput('company')"
      />
      <select-element
        class="margin-top"
        :model-value="inputs.industry.id"
        :error="
          inputs.industry.id !== '*' && inputErrors.industry
            ? 'Field is required'
            : ''
        "
        :options="industriesOptions.concat({ value: '*', text: 'Other' })"
        label="Industry"
        @blur="validateInput('industry')"
        @update:model-value="updateIndustryValue"
      />
      <input-element
        v-show="inputs.industry.id === '*'"
        v-model="inputs.industry.value"
        :error="inputErrors.industry ? 'Field is required' : ''"
        class="margin-top"
        label="Please specify your industry name"
        @blur="validateInput('industry')"
      />
    </div>
    <div class="jcc">
      <button-element @click="updateProfile" :disabled="fetchingProfile">
        Continue
        <progress-element class="xta" v-show="fetchingProfile" active />
      </button-element>
    </div>
  </template>

  <template v-else-if="step === 'intro'">
    <div class="xqd">
      <img class="qvc" src="images/3bd4.png" />
    </div>
    <div class="qfd">
      <div class="hnq">Corporate Innovation Maturity Model</div>
      <div class="ess">consist of 6 factors under two attributes which are</div>
      <div class="hnq">People attributes:</div>
      <div class="ess">
        Leadership, Team Capability and Organizational Process
      </div>
      <div class="hnq">Technology attributes:</div>
      <div>Tools, Data and Ecosystem</div>
    </div>
    <div class="jcc">
      <button-element @click="step = 'test'" :disabled="fetchingSections">
        Continue
        <progress-element class="xta" :active="fetchingSections" />
      </button-element>
    </div>
  </template>

  <template v-else-if="step === 'test'">
    <section-indicator-element
      class="jsa"
      :total="sections.length"
      :current="currentSection + 1"
      :progress="progress"
    />
    <template v-for="(section, index) in sections" :key="`section-${index}`">
      <template v-if="index === currentSection">
        <template v-if="currentQuestion === 0">
          <div class="fade-in">
            <div
              class="kjr topic-question"
              :class="[`is-${sectionData[index].color}`]"
            >
              {{ sectionData[index].topic }}
            </div>
            <div class="hnq">{{ sectionData[index].title }}</div>
            <p>{{ section.description }}</p>
            <div class="abo" @click="backSection">
              Back
            </div>
            <div class="jcc">
              <button-element
                :color="sectionData[index].color"
                :data-topic="sectionData[index].topic.toLowerCase()"
                @click="startNewSection(sectionData[index].topic)"
              >
                Continue
              </button-element>
            </div>
          </div>
        </template>

        <template v-else-if="currentQuestion > 0">
          <template
            v-for="(question, qIndex) in section.questions"
            :key="question.id"
          >
            <template v-if="qIndex + 1 === currentQuestion">
              <div class="fade-in">
                <div class="qfd">
                  <div class="hnq">{{ question.topic }}</div>
                  <div>{{ question.title }}</div>
                </div>
                <template v-for="choice in question.choices" :key="choice.id">
                  <radio-element
                    class="ikp"
                    :class="{ 'is-active': choice.id === answers[question.id] }"
                    :color="sectionData[index].color"
                    icon="check_circle"
                    :value="choice.id"
                    v-model="answers[question.id]"
                    @click.once="
                      (event) => selectAnswer(event, section.questions.length)
                    "
                  >
                    <div class="myr">{{ choice.text }}</div>
                  </radio-element>
                </template>
                <div class="abo" @click="currentQuestion -= 1">
                  Back
                </div>
                <template v-if="isFinalQuestion">
                  <div class="jcc fade-in">
                    <button-element
                      @click="submitTest"
                      class="submit-test"
                      :disabled="submittingAnswers"
                    >
                      Submit
                      <progress-element
                        class="xta"
                        v-show="submittingAnswers"
                        active
                      />
                    </button-element>
                  </div>
                </template>
              </div>
            </template>
          </template>
        </template>
      </template>
    </template>
  </template>

  <template v-else-if="step === 'completed'">
    <section-indicator-element
      class="jsa"
      :total="sections.length"
      :current="currentSection + 1"
      :progress="progress"
    />
    <div class="qfd">
      <h3>Congratulations!</h3>
      <div>
        You have completed Corporate Innovation Maturity Assessment. Please
        continue to see the results and download the detailed report.
      </div>
    </div>
    <div class="abo" @click="step = 'test'">
      Back
    </div>
    <div class="jcc">
      <router-link
        :to="{
          name: 'result',
          params: { id: resultId },
          query: {
            industryId: industryId
          }
        }"
      >
        <button-element>See Results</button-element>
      </router-link>
    </div>
  </template>

  <dialog-element ref="exitDialog">
    <div class="zfx">
      <div class="asd">Abort test?</div>
      <div class="lwv">
        Leaving this page will abort and reset the test progress.<br />
        Are you sure you want to proceed?
      </div>
      <div class="ooh">
        <button-element class="mrl" outlined @click="abortTest">
          Abort
        </button-element>
        <button-element class="mrl" @click="exitDialog.hide()">
          No
        </button-element>
      </div>
    </div>
  </dialog-element>
  <dialog-element ref="errorDialog">
    <div class="zfx">
      <div class="asd">Error</div>
      <div class="lwv">
        <template v-if="errorCode === 'unknown'"
          >Oops! Something went wrong. Please try again later.</template
        >
      </div>
      <div class="ooh">
        <button-element color="red" @click="errorDialog.hide()">
          Close
        </button-element>
      </div>
    </div>
  </dialog-element>
</template>

<script>
import { computed, onMounted, ref, onUnmounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import store from "@/store";
import {
  getIndustryList,
  getCompanyList,
} from "@/services/firebase/masterdata.js";
import {
  FETCH_PROFILE,
  FETCH_SECTIONS,
  GET_USER,
  SUBMIT_TEST,
  UPDATE_PROFILE,
} from "@/store/action-types";

import { initialFuse } from "@/helpers/fuse";
import CompanyInput from "@/components/company-input/company-input.vue";
import SectionIndicator from "./section-indicator.vue";
import sectionData from "./section-data.json";
import useInputs from "./use-inputs";
import BaseInputWithSuggestion from "../../components/BaseInputWithSuggestion.vue";

export default {
  components: {
    "company-input-component": CompanyInput,
    "section-indicator-element": SectionIndicator,
    "base-input-with-suggestion": BaseInputWithSuggestion,
  },

  async beforeRouteEnter(to, from, next) {
    let user = await store.dispatch(GET_USER);
    if (!user) {
      next({ name: "login" });
      return;
    }
    next();
  },

  async beforeRouteLeave(to, from, next) {
    let { name, params } = to;
    this.nextRoute = {
      name,
      params,
    };
    if (!this.abortConfirmed && this.step === "test") {
      this.exitDialog.show();
      next(false);
      return;
    }

    next();
  },

  setup() {
    const dataLayer = window.dataLayer;
    let store = useStore();
    let router = useRouter();
    let currentTopicName = ref("");
    let abortConfirmed = ref(false);
    let answers = ref({});
    let consent = ref(false);
    let currentQuestion = ref(0);
    let currentSection = ref(0);
    let disabledSelect = ref(false);
    let errorCode = ref("");
    let errorDialog = ref(null);
    let exitDialog = ref(null);
    let fetchingProfile = ref(false);
    let fetchingSections = ref(true);
    let isFinalQuestion = ref(false);
    let nextRoute = ref(null);
    let resultId = ref(null);
    let step = ref("consent");
    let submittingAnswers = ref(false);
    let industryId = computed(() => store.state.user.industry.id);

    let companiesOptions = computed(() => {
      const options = {
        threshold: 0.6,
        useExtendedSearch: true,
        keys: ["text"],
      };
      const companies = initialFuse(companyList.value, options);
      const results = companies
        .search(`^${inputs.value.company.value}`)
        .map(({ item }) => item);
      return results;
    });

    let companyList = ref([]);
    let industriesOptions = ref([]);

    let {
      inputs,
      inputErrors,
      populateInputs,
      validateInput,
      validateInputs,
    } = useInputs();

    let profile = computed(() => store.state.user.profile);

    let progress = computed(() => {
      let section = sections.value[currentSection.value];
      if (!section) return 0;
      let totalQuestion = section.questions.length;
      let ratio = parseFloat(currentQuestion.value / totalQuestion) * 100;
      return ratio;
    });

    let sections = computed(() => store.state.test.sections);

    let abortTest = () => {
      abortConfirmed.value = true;
      router.push(nextRoute.value);
    };

    let backSection = () => {
      if (currentSection.value > 0) {
        currentSection.value -= 1;
        currentQuestion.value =
          sections.value[currentSection.value].questions.length;
      } else if (currentSection.value === 0) {
        step.value = "intro";
        currentQuestion.value = 0;
      }
      isFinalQuestion.value = false;
    };

    let delayHandleSelect = (length) => {
      return new Promise((resolve, reject) => {
        disabledSelect.value = true;
        setTimeout(() => {
          if (
            currentSection.value === sections.value.length - 1 &&
            currentQuestion.value === length
          ) {
            isFinalQuestion.value = true;
          } else if (currentQuestion.value === length) {
            currentSection.value += 1;
            currentQuestion.value = 0;
            isFinalQuestion.value = false;
          } else {
            currentQuestion.value += 1;
            isFinalQuestion.value = false;
          }
          disabledSelect.value = false;
          resolve();
        }, 1000);
      });
    };

    let fetchProfile = async () => {
      try {
        fetchingProfile.value = true;
        await store.dispatch(FETCH_PROFILE);

        // if there is UserId from Firebase
        // BUT no MobileNumber in profile, it's guest mode
        // We need to let them input contact info for sales purpose
        if (profile.value.mobileNumber == "") {
          fetchingProfile.value = false;
          router.push({ name: "register-guest" });
        }

        populateInputs(profile.value);
      } catch (error) {
        console.error(error);
        errorCode.value = "unknown";
        errorDialog.value.show();
      } finally {
        fetchingProfile.value = false;
      }
    };

    let fetchSections = async () => {
      try {
        fetchingSections.value = true;
        await store.dispatch(FETCH_SECTIONS);
      } catch (error) {
        console.error(error);
        errorCode.value = "unknown";
        errorDialog.value.show();
      } finally {
        fetchingSections.value = false;
      }
    };

    let selectAnswer = async (event, length) => {
      if (disabledSelect.value) {
        event.preventDefault();
        return;
      }
      await delayHandleSelect(length);
    };

    let submitTest = async () => {
      let formattedAnswers = Object.keys(answers.value).map((qId) => {
        return {
          questionId: qId,
          choiceId: answers.value[qId],
        };
      });
      try {
        submittingAnswers.value = true;
        let { data } = await store.dispatch(SUBMIT_TEST, formattedAnswers);
        resultId.value = data.id;
      } catch (error) {
        errorCode.value = "unknown";
        errorDialog.value.show();
      } finally {
        submittingAnswers.value = false;
        step.value = "completed";
      }
    };

    const fetchCompaniesList = async () => {
      const companiesSnap = await getCompanyList();

      const companies = companiesSnap.map(({ id, name }) => {
        return {
          value: id,
          text: name,
        };
      });

      companyList.value = companies;
    };

    const fetchIndustriesList = async () => {
      const result = await getIndustryList();
      industriesOptions.value = result.map(({ id, name }) => {
        return {
          value: id,
          text: name,
        };
      });
    };

    let updateProfile = async () => {
      let profile = validateInputs();

      if (!profile) return;
      try {
        fetchingProfile.value = true;
        await store.dispatch(UPDATE_PROFILE, { profile });
        step.value = "intro";
      } catch (error) {
        errorCode.value = "unknown";
        errorDialog.value.show();
      } finally {
        fetchingProfile.value = false;
      }
    };

    const updateCompanyValue = (value) => {
      inputs.value.company = value;
    };

    const updateIndustryValue = (value) => {
      inputs.value.industry = {
        id: value,
      };
    };

    const startNewSection = (topic) => {
      const topicName = topic
        .toLowerCase()
        .split(" ")
        .join("_");
      currentQuestion.value += 1;
      dataLayer.push({
        event: `start_topic`,
        topic: topicName,
      });
      currentTopicName.value = topicName;
    };

    const beforeUnload = () => {
      if (step.value !== "test") {
        dataLayer.push({
          event: "drop_off_topic",
          topic: step.value,
        });
        return;
      }

      if (currentTopicName.value) {
        dataLayer.push({
          event: "drop_off_topic",
          topic: currentTopicName.value,
        });
        return;
      }
    };
    window.addEventListener("beforeunload", beforeUnload);

    onMounted(async () => {
      try {
        await fetchProfile();
        await fetchSections();
        await fetchIndustriesList();
        await fetchCompaniesList();
      } catch (e) {
        console.log(e);
      }
    });

    return {
      beforeUnload,
      abortConfirmed,
      abortTest,
      answers,
      backSection,
      consent,
      startNewSection,
      currentQuestion,
      currentSection,
      errorCode,
      errorDialog,
      exitDialog,
      fetchingProfile,
      fetchingSections,
      selectAnswer,
      inputErrors,
      inputs,
      isFinalQuestion,
      nextRoute,
      profile,
      progress,
      resultId,
      industryId,
      router,
      sectionData,
      sections,
      step,
      submitTest,
      submittingAnswers,
      updateProfile,
      validateInput,
      updateCompanyValue,
      updateIndustryValue,
      companiesOptions,
      industriesOptions,
      dataLayer,
    };
  },
};
</script>

<style lang="scss" scoped>
@use 'sass:map';
@use '@/styles/variables';

.qfd {
  background-color: white;
  padding: 24px;
  border-radius: 4px;
}

.kjr {
  color: map.get(variables.$colors, "orange", "500");
  font-size: map.get(variables.$font-sizes, "lg");
  font-weight: map.get(variables.$font-weights, "bold");
  margin: 16px 0 24px;

  @each $name, $color in variables.$colors {
    &.is-#{$name} {
      color: map.get($color, "500");
    }
  }
}

.hnq {
  font-weight: map.get(variables.$font-weights, "bold");
  margin-bottom: 8px;
}

.ess {
  margin-bottom: 24px;
}

.myr:hover {
  cursor: pointer;
}

.abo {
  color: map.get(variables.$colors, "gray", "500");
  text-decoration: underline;
  margin-top: 16px;
  width: max-content;

  &:hover {
    cursor: pointer;
  }
}

.jcc {
  margin-top: 16px;
  margin-bottom: 48px;
  text-align: right;
}

.xqd {
  text-align: center;
  margin-bottom: 24px;
}

.qvc {
  width: 100%;
}

.ikp {
  padding: 16px;
  margin: 16px 0;
  background-color: white;
  display: flex;
  align-items: center;
  border-radius: 4px;

  transition-duration: 0.1s;
  transition-property: opacity;
  transition-timing-function: ease-out;

  &.is-active {
    animation: 0.25s ease 0s 2 normal none running pulse;
  }

  &:hover {
    cursor: pointer;
  }
}

@keyframes pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.jsa {
  padding: 16px 0 24px;
}

.zfx {
  padding: 24px;
}

.asd {
  font-weight: map.get(variables.$font-weights, "bold");
  font-size: map.get(variables.$font-sizes, "lg");
}

.lwv {
  margin: 16px 0 24px;
}

.ooh {
  text-align: right;
}

.mrl:not(:last-child) {
  margin-right: 16px;
}

.vzc {
  border-radius: 24px;
  width: max-content;
  padding: 8px;
  margin: 16px 0 24px;
  font-size: map.get(variables.$font-sizes, "sm");
  background-color: map.get(variables.$colors, "yellow", "500");
  color: white;
}

.accept-consent {
  &:hover {
    cursor: pointer;
  }
}

.accept-consent-remark {
  font-size: 14px;
  margin-left: 36px;
  color: map.get(variables.$colors, "red", "700");
}

.npe {
  display: block;
  width: max-content;
}

.margin-top {
  margin-top: 16px;
}

.xta {
  margin-left: 16px;
}

.fade-in {
  animation: fadeIn ease-in-out 0.5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

@media (min-width: map.get(variables.$breakpoints, "xs")) {
  .qvc {
    max-height: 400px;
  }
}

@media (min-width: map.get(variables.$breakpoints, "sm")) {
  .qvc {
    max-height: 540px;
  }
}

@media (min-width: map.get(variables.$breakpoints, "md")) {
  .qvc {
    width: inherit;
  }
}

@media (min-width: map.get(variables.$breakpoints, "lg")) {
  .qfd {
    padding: 32px;
  }

  .jcc {
    text-align: center;
    margin-bottom: 64px;
  }

  .xqd {
    margin-bottom: 32px;
  }

  .zfx {
    padding: 32px;
  }

  .lwv {
    margin: 24px 0 32px;
  }

  .ooh {
    text-align: center;
  }
}
</style>
