<template>
  <div class='section-indicator'>
    <div
      v-for='section in total'
      :key='section'
      class='igt'
      :class='[
        { "is-current": section <= current },
        [`is-${colors[section - 1]}`]
      ]'>
      <div class='foy'
        :class='[
          [`is-${colors[section - 1]}`]
        ]'
        :style='[
          `width: ${current > section ? "100" : progress}%`]
        '/>
    </div>
  </div>
</template>

<script>
  import { ref } from 'vue'

  export default {
    props: {
      total: Number,
      current: Number,
      progress: Number
    },

    setup () {
      let colors = ref(
        [
          'orange',
          'purple',
          'indigo',
          'amber',
          'green',
          'blue'
        ]
      )
      return {
        colors
      }
    }
  }
</script>

<style lang='scss' scoped>
  @use 'sass:map';
  @use '@/styles/variables';

  .section-indicator {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    gap: 8px;
    width: 100%;
    box-sizing: border-box;
    margin: 24px 0 32px;
  }

  .igt {
    height: 4px;
    width: 100%;
    border-radius: 8px;
    background-color: map.get(variables.$colors, 'gray', '300');

    @each $name, $color in variables.$colors {
      &.is-current.is-#{$name} {
        background-color: map.get($color, '300');
      }
    }
  }

  .foy {
    height: 100%;

    @each $name, $color in variables.$colors {
      .is-current &.is-#{$name} {
        background-color: map.get($color, '500');
      }
    }
  }
</style>
